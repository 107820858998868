// Libraries
import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
// Components
import Seo from '../components/seo';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Tags from '../components/tags';
// Styles
import * as styles from './rules-post.module.css';
// Utils
import { RULES_SHOW } from '../utils/routeConstants';

class RulesPostTemplate extends React.Component {
  render() {
    const rule = get(this.props, 'data.contentfulRules');
    const previous = get(this.props, 'data.previous');
    const next = get(this.props, 'data.next');

    return (
      <Layout location={this.props.location}>
        <Seo
          title={rule.title}
          description={rule.description}
          image={`http:${rule?.heroImage?.resize.src}`}
        />
        <Hero
          image={rule.heroImage?.gatsbyImageData}
          title={rule.title}
          content={rule.description}
        />
        <div className={styles.container}>
          <span className={styles.meta}>
            <time dateTime={rule.rawDate}>{rule.publishedDate}</time> ~{' '}
            {rule.body.childMarkdownRemark.timeToRead} minute read
          </span>
          <div className={styles.article}>
            <div
              className={styles.body}
              dangerouslySetInnerHTML={{
                __html: rule.body.childMarkdownRemark.html,
              }}
            />
            <Tags tags={rule.tags} />
            {(previous || next) && (
              <nav>
                <ul className={styles.articleNavigation}>
                  {previous && (
                    <li>
                      <Link to={RULES_SHOW(previous.slug)} rel="prev">
                        ← {previous.title}
                      </Link>
                    </li>
                  )}
                  {next && (
                    <li>
                      <Link to={RULES_SHOW(next.slug)} rel="next">
                        {next.title} →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

export default RulesPostTemplate;

export const pageQuery = graphql`
  query RulesPostBySlug(
    $slug: String!
    $previousRuleSlug: String
    $nextRuleSlug: String
  ) {
    contentfulRules(slug: { eq: $slug }) {
      slug
      title
      description
      publishedDate(formatString: "MMMM Do, YYYY")
      rawDate: publishedDate
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
      tags
    }
    previous: contentfulRules(slug: { eq: $previousRuleSlug }) {
      slug
      title
    }
    next: contentfulRules(slug: { eq: $nextRuleSlug }) {
      slug
      title
    }
  }
`;
